@font-face {
  font-family: Poppins;
  font-weight: 300;
  src: url('/fonts/Poppins-Light.woff') format('woff');
}

@font-face {
  font-family: Poppins;
  font-weight: 400;
  src: url('/fonts/Poppins-Regular.woff') format('woff');
}

@font-face {
  font-family: Poppins;
  font-weight: 500;
  src: url('/fonts/Poppins-Medium.woff') format('woff');
}

@font-face {
  font-family: Poppins;
  font-weight: 700;
  src: url('/fonts/Poppins-Bold.woff') format('woff');
}

body {
  font-family: Poppins, sans-serif;
}
